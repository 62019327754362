import Link from 'next/link'

export const LinkController = (props) => {
    // Navigation Link component should be passed in as a prop, or configured once
    const relativeUrl = props.href?.startsWith('/')
    const Component = relativeUrl ? Link : 'a'

    return (
        <Component
            {...props}
            rel={props.rel || (relativeUrl ? undefined : 'noreferrer')}
            target={props.target || (relativeUrl ? undefined : '_blank')}
        />
    )
}
